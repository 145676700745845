import React from 'react';
import { useState } from 'react';

const SiteShell = ({ site }) => {

    console.log(site);
    return (
        <div className="">
            <h1>Selected::::{site.site_name}</h1>
            <SiteTabs site={site} />
        </div>
    );
};


const SiteTabs = ({ site }) => {
    const [activeTab, setActiveTab] = useState('tab1');

    const tabsList = ['tab1', 'tab2', 'tab3'];

    const TabContainer = (children) => {
        return <div className='tab'>{children}</div>;
    }
   
    const renderTabContent = () => {
        switch (activeTab) {
            case 'tab1':
                return <div className='tab'>Content for Tab 1</div>;
            case 'tab2':
                return <div>Content for Tab 2</div>;
            case 'tab3':
                return <div>Content for Tab 3</div>;
            default:
                return null;
        }
    };

    return (
        <div className="">
            <h1>Selected::::{site.site_name}</h1>
            <div className="tab_container">
                {tabsList.map((tab) => (
                    <button key={tab} onClick={() => setActiveTab(tab)}>
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                ))}
            </div>
            <div className="tab-content">
                {renderTabContent()}
            </div>
        </div>
    );
};

export default SiteShell;