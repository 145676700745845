import { useEffect } from 'react';
import { apiPost } from '../../data/apidata';
import { useState } from 'react';


const Subscriptions=()=>{

    const [subscriptions, setSubscriptions] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    function getSubscriptions(){
        apiPost("api/messages/subscription/view", 
            { subscriptionName: "test", userToken: "test" }, 
            (response) => {
                console.log("response");
                try {
                    setSubscriptions(response.data);
                }
                catch (error) {
                    console.log("Error parsing response data"); 
                }
                
            }, 
            (error) => {
                console.log(error);
            }      
        );
    };

    useEffect(() => {
        // Function to call on first load
        const onFirstLoad = () => { getSubscriptions();};

        onFirstLoad();
    }, []);

    function newSubscriptionClick(){
        setShowForm(true);
    }

    return(
        <div>
            <h1>Your Subscriptions</h1>
            {showForm ? <SubscriptionForm onUpdated={getSubscriptions} onClose={()=>setShowForm(false)} /> : <button onClick={() => newSubscriptionClick()}>Add New Subscription</button>}
            <div style={styles.container}>
                
                {subscriptions.length > 0 ? (
                <div style={styles.grid}>
                    {subscriptions.map((sub) => (
                    <SubscriptionCard key={sub.id} subscription={sub} onShow={setShowDetails} />
                    ))}
                </div>
                ) : (
                <p>No subscriptions found.</p>
                )}
            {showDetails ? <ShowSubscriptionDetails subscription={showDetails} /> : null}
                
        </div>
      </div>
    );
}


// SubscriptionCard Component
const SubscriptionCard = ({ subscription, onShow }) => {

    function handleClick() {
        console.log(`You clicked on ${subscription.name}`);
        onShow(subscription);
    }

    return (
      <div className="subscription-card" style={styles.card} onClick={handleClick} >
        <h3>{subscription.name}</h3>
        <p>Price: {subscription.price}</p>
        <p>Renewal Date: {subscription.renewalDate}</p>
      </div>
    );
  };


  // CSS styles as JavaScript objects
const styles = {
    container: {
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      //display: 'flex',
      //flexDirection: 'row   ',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
      gap: '20px',
    },
    card: {
      border: '1px solid #ccc',
      borderRadius: '8px',
      padding: '16px',
      textAlign: 'center',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    },
  };

  const SubscriptionForm=({onClose, onUpdated})=>{

    function handleSubmit(event){
        event.preventDefault();
        console.log("Form submitted");
        console.log(event.target.subscriptionName.value);

        apiPost("api/messages/subscription/add",
            {"subscriptionName": event.target.subscriptionName.value, "userToken":"dsd"}, 
            (response)=>{
                onUpdated();
                onClose();
            },
            (error)=>{
                console.log(error);
            }
        );
    }

    return(
        
        <div style={styles.container}>
            <h1>New Subscription</h1>
            <form onSubmit={handleSubmit}>
                <label>Subscription Name</label>
                <input type="text" name="subscriptionName"  />
                {/*}
                <label>Price</label>
                <input type="text" name="price" />
                <label>Renewal Date</label>
                <input type="text" name="renewalDate" />
                */}
                <button type="submit">Save</button>
                <button type="button" onClick={onClose}>Close</button>
            </form>
        </div>
    );
}


  const SubscriptionValues=()=>{

    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        // Function to call on first load
        const onFirstLoad = () => {
            console.log('Page loaded for the first timesss');

            apiPost("api/messages/subscription/option/view", 
                { userToken: "test" }, 
                (response) => {
                    console.log("response");
                    try {
                        setSubscriptions(response.data);
                    }
                    catch (error) {
                        console.log("Error parsing response data"); 
                    }
                    
                }, 
                (error) => {
                    console.log(error);
                }      
            );
        };

        onFirstLoad();
    }, []);

    return(
        <div style={styles.container}>
            {subscriptions.length > 0 ? (
            <div style={styles.grid}>
                {subscriptions.map((sub) => (
                <div>{sub.name}</div>
                ))}
            </div>
            ) : (
            <p>No subscriptions found.</p>
            )}
      </div>
    );
}

const ShowSubscriptionDetails=({subscription})=>{
    return(
        <div>
            <h1>Subscription Details</h1>
            <p>Name: {subscription.name}</p>
            {/*<p>Price: {subscription.price}</p>
            <p>Renewal Date: {subscription.renewalDate}</p>*/}
            <SubscriptionValues />
        </div>
    );
}




export default Subscriptions;