import { FormInput } from "../Input";
import "./NewZoneProfilePage.css";
import { apiPost } from "../../data/apidata";
import { useUserSettings } from "../UserSettingsProvider/UserSettingsProvider";
import React, { useEffect, useState } from "react";
import { TextField, Button, Checkbox, FormControlLabel, FormGroup, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const NewZoneProfilePage = ({ siteCode, onClose, profileData }) => {
console.log("siteCode", siteCode);
    return (
        <div className="new_zone_profile_page_container">
            <h1>{(profileData) ? `Edit Zone Profile` : "Create Zone Profile"}</h1>
            <div>
                <ZoneProfilePage siteCode={siteCode} onClose={onClose} profileData={profileData} />
            </div>

        </div>
    );
}


const ZoneProfilePage = ({ siteCode, onClose, profileData }) => {
    // material ui form that will handle new forms for zone profile creation having the following fields:
    // - profile name
    // a list of possible zones
    // a list of call type (emergency, non-emergency, etc) for each zone

    const [profileName, setProfileName] = useState("");
    const [zones, setZones] = useState([]);
    const [callTypes, setCallTypes] = useState({});
    const [profileID, setProfileID] = useState(profileData?.id || null);

    console.log("profileData", profileData);

    const handleProfileNameChange = (event) => {
        setProfileName(event.target.value);
    };

    const [errorMessage, setErrorMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateProfileName = (event) => {
        event.preventDefault();
        if (!profileName.trim()) {
            setErrorMessage("Zone Profile name is required.");
            return;
        }
        setErrorMessage("");
        handleSubmit();
        // Handle form submission
    };


    const handleSubmit = () => {
        // Handle form submission
        console.log({ profileName, zones, callTypes });
        setIsSubmitting(true);

        createZoneProfileData(siteCode, profileID, profileName, zones, callTypes, 
            (response) => {
                console.log(response);
                setIsSubmitting (false);
                onClose();
            }, 
            (error) => {
                setErrorMessage(error);
                setIsSubmitting(false);
            });
    };

    useEffect(() => {
        getZoneData(siteCode, (data) => {
            console.log("zonedata", data);
            setZones(data);
        }, (e) => {
            console.log("zonedata error", e);
        });
    }, [siteCode]);

    useEffect(() => {
        if (!profileData) return;
        setProfileName(profileData.name);

        const newCallTypes = {};
        profileData.zones.forEach((zone) => {
            if (zone.zoneSelected===true) {
                
                Object.keys(zone).forEach((key) => {
                    const displayName = getZoneSettingDisplayValue(key);
                    if (displayName==="") return;
                    if (zone[key]===true) {
                        if (!newCallTypes[zone.name]) newCallTypes[zone.name] = [];
                        newCallTypes[zone.name].push(displayName);
                    } 
                });
                
            }
            else {
                newCallTypes[zone.name] = [];
            }
        });
        
        setCallTypes(newCallTypes);
        setProfileID(profileData.id);

    }, [profileData]);

    return (
        <ThemeProvider theme={theme}>
            <form onSubmit={validateProfileName}>
                {isSubmitting && <p>Submitting...</p>}
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                <TextField
                    label="Name"
                    value={profileName}
                    onChange={handleProfileNameChange}
                    fullWidth
                    margin="normal"
                    disabled={isSubmitting}
                />
                <div style={{ height: 20 }} />
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                        <div style={{ width: "100px" }}><b>Select Zone</b></div> 
                        <div><b>Assign Call Types</b></div>
                    </div>
                {
                    zones &&
                    zones.map((zone) => (
                        <FormGroup row key={zone}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={callTypes[zone]?.length > 0}
                                        icon={<RadioButtonUncheckedIcon />}
                                        checkedIcon={<CheckCircleIcon />}
                                        onChange={(event) => {
                                            const allTypes = ["Call", "Emergency", "Assistance","Attendance", "Sense"];
                                            setCallTypes({
                                                ...callTypes,
                                                [zone]: event.target.checked ? allTypes : []
                                            });
                                        }}
                                        disabled={isSubmitting}
                                    />
                                }
                                label={zone}
                                style={{ flex: 2, width: "100px" }}
                            />
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                {["Call", "Emergency", "Assistance", "Attendance", "Sense"].map((type) => (
                                    <FormControlLabel
                                        key={type}
                                        control={
                                            <Checkbox
                                                icon={<RadioButtonUncheckedIcon />}
                                                checkedIcon={<CheckCircleIcon />}
                                                checked={callTypes[zone]?.includes(type) || false}
                                                onChange={(event) => {
                                                    const newCallTypes = callTypes[zone] || [];
                                                    if (event.target.checked) {
                                                        newCallTypes.push(type);
                                                    } else {
                                                        const index = newCallTypes.indexOf(type);
                                                        if (index > -1) {
                                                            newCallTypes.splice(index, 1);
                                                        }
                                                    }
                                                    setCallTypes({
                                                        ...callTypes,
                                                        [zone]: newCallTypes
                                                    });
                                                }}
                                                disabled={!callTypes[zone]?.length || isSubmitting}
                                            />
                                        }
                                        label={type}
                                        style={{ flex: 1 }}
                                    />
                                ))}
                            </div>
                            </div>
                        </FormGroup>
                    ))}
                <div style={{ height: 20 }} />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>&nbsp;</div>
                    <div className="flex-row">
                        <button type="submit" class="validate_button">Create</button>
                        <button class="close_buton uniForm_btn" onClick={() => onClose()} disabled={isSubmitting}>Close</button>
                        
                        {/*}
                        <Button type="button" variant="contained" color="primary" onClick={() => onClose()} disabled={isSubmitting}>
                            Cancel
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                            Save
                        </Button>
                        */}
                    </div>
                </div>
            </form>
        </ThemeProvider>
    );
};


function getZoneData(siteCode, onSuccess, onError) {

    apiPost(
        "api/messages/site/config/zones",
        {
            "siteCode": siteCode
        },
        (d) => {
            try {
                onSuccess(d.message[0].map((zone) => zone.zone_name))
            } catch (e) {
                console.log("errorsss", e);
                onError(e);
            }
        },
        (e) => { onError(e) }
    )
}


function createZoneProfileData(siteCode, profileID, profileName, zones, callTypes, onSuccess, onError) {

    apiPost(
        "api/messages/zoneprofiles/add",
        {
            "profileID": profileID,
            "siteCode": siteCode,
            "profileName": profileName, 
            "zones": zones, 
            "callTypes": callTypes
        },
        (d) => {
            try {
                console.log("response", d);
                onSuccess(d.message)
            } catch (e) {
                console.log("errorsss", e);
                onError(e);
            }
        },
        (e) => { onError(e) }
    )
}

/*
function to return the display value for the below fields
"accSelected", "assSelected", "attSelected", "emgSelected", "callSelected", "zoneSelected"
*/
function getZoneSettingDisplayValue(val){
    console.log("valsasa", val);
    if (val==="emgSelected") return "Emergency";
    if (val==="callSelected") return "Call";
    if (val==="assSelected") return "Assistance";
    if (val==="accSelected") return "Sense";
    if (val==="attSelected") return "Attendance";
    return "";
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#4cc1bd', // Green color
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 12.5,
    },
    components: {
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: '12.5px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#ffffff', // Makes button text white
                },
            },
        },

        MuiCheckbox: {
            styleOverrides: {
                root: {
                    fontSize: '12.5px',
                },
                checked: {
                    color: '#1976d2', // Customize color when checked (optional)
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    fontSize: '12.5px',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '12.5px',
                },
            },
        },
    },
});

export default NewZoneProfilePage;