import React from 'react';
import { useEffect } from 'react';
import { apiPost } from '../../data/apidata';
import SiteShell from './SiteShell';


const Master = () => {
    
    const [isFirstLoad, setIsFirstLoad] = React.useState(true);
    const [sitesList, setSitesList] = React.useState(true);
    const [masterList, setMasterList] = React.useState(true);
    const [selectedSite, setSelectedSite] = React.useState(true);

    useEffect(() => {
        if (isFirstLoad) {
            getSiteData();
            setIsFirstLoad(false);
        }
    }, [isFirstLoad]);

    function getSiteData() {
        
        apiPost('/api/messages/site/management/list', { "userID": "1" },
            (data)=>{

                try {
                    setMasterList(data.message.data[0]);
                }
                catch (e) { console.log(e); }
                console.log(data);
            },
            (err)=>{
                console.log(err);
            }
        );

    }

    useEffect(() => { setSitesList(masterList); }, [masterList]);

    //check if sitesList is empty
    if (masterList.length === 0) {
        return (
            <div>
                <h1>No Sites Found</h1>
            </div>
        );
    }

    //check if siteList is an array
    if (!Array.isArray(sitesList)) {
        return (
            <div>
                <h1>Invalid Data</h1>
            </div>
        );
    }

    return (
        <div>
            <input 
                type="text" 
                list="sites" 
                placeholder="Search Sites..." 
                onChange={(e) => {
                    const value = e.target.value.toLowerCase();

                    const filteredSites = masterList.filter(site => 
                        site.site_name.toLowerCase().includes(value)
                    );
                    setSitesList(filteredSites);
                    }
                }
                
            />
            <button 
                onClick={() => {
                    const input = document.querySelector('input[list="sites"]').value;
                    const selectedSite = masterList.find(site => site.site_name === input);
                    if (selectedSite) {
                        setSelectedSite(selectedSite);
                    } else {
                        setSelectedSite(null);
                    }
                }}
            >
                Select Site
            </button>
            <datalist id="sites">
                {sitesList.map((site, index) => (
                    <option key={index} value={site.site_name} />
                ))}
            </datalist>


            {selectedSite && <SiteShell site={selectedSite} />}

        </div>
    );
};

export default Master;