import React, { useEffect, useState,useRef } from 'react';
import { FormInput } from '../../../components/Input';
import './connectUserEdit.css';
import { apiPost } from '../../../data/apidata';
import { useSnackbar } from 'notistack';
import DELETE_ICON from '../../../images/settings/delete.png';
import axios from 'axios';

const ConnectUserEdit=({siteID, userData, onClose})=>{
    //console.log(userData);
    const { enqueueSnackbar } = useSnackbar();
    
    const [formData, setFormData] = useState({
        siteID: `${siteID}`,
        title: `${userData.title}`,
        Fname: `${userData.firstName}`,
        surname: `${userData.surname}`,
        email: `${userData.email}`,
        role: `${userData.role}`,
        username:`${userData.username}`,
        selected_site: `${userData.selected_site}`,
    });
    const [passwordError, setPasswordError] = useState("");
    const [error, setError] = useState("");


    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        }));
    };

    function siteChange(val) {
        console.log("siteChange", val);
        setFormData((prevData) => ({
        ...prevData,
        ["siteID"]: val,
        }));
    };
    

    function cleanError(message){
        console.log({message});
        if (message.indexOf("Object didn't pass validation for format email:")>-1) return `Invalid email address format.`
        return message;
    }

    function updateUserRole(id, role){
        apiPost(
            'api/messages/site/user/updateUserRole',
            {
                userID: id,
                role: `${role}`
            },
            (d)=>{console.log('response', d)},
            (e)=>{console.log('response', e)}
        )
    }

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // You can perform actions with formData here, such as sending it to an API
        //console.log('Form data submitted:', formData);

        apiPost(
            "api/messages/user/site/update",
            {
        id: `${userData.id}`,
        title: `${formData.title}`,
        firstname: `${formData.Fname}`,
        surname: `${formData.surname}`,
        email: `${userData.email}`,
        role: `${formData.role}`,
        username: `${userData.username}`
    },
            (d)=>{ 
                enqueueSnackbar("User Updated", {variant:"success"});
                updateUserRole(userData.id, formData.role);
                if (onClose) onClose();
            },
            (e)=>{
                if (e.toString().indexOf("Password")>-1) {
                    setPasswordError(e);
                }
                else {
                    if (e==="##ERROR##::siteID is not a number.") {
                        setError("You have not selected a site to assign the user to.");
                    }
                    else {
                        setError(e);
                    }
                }
            }
        )

    };
               
    return (
        <div className='new_user_page_container'>
            <h1>Edit User</h1>
            <div className='aqForm' >
            {(error!=="")&&<><small className='formFieldError'>{error}</small><br /></>}
            
            <div className='aq_fields' >
            
            <label>
            Title:
            <TitleInput
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
            />
            </label>
            <br />

            <label>
            Name:
            <FormInput
                type="text"
                name="Fname"
                value={formData.Fname}
                onChange={handleChange}
            />
            </label>
            <br />
            <label>
            Surname:
            <FormInput
                type="text"
                name="surname"
                value={formData.surname}
                onChange={handleChange}
            />
            </label>
            <br />
            <label>
            Email:
            <input
            className="email_readonly shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                readOnly
                type="email"
                name="email"
                value={formData.email}
            />
            </label>
            <br />
            <label>
            Role:
            <SelectInput
                name="role"
                value={formData.role}
                onChange={handleChange}
            />
            </label>
            <br />
            <div>
                <SiteAssigment email={userData.email} userID={userData.id} selected_site_id={userData.selected_site}/>
            </div>

            </div>
            <div className='submit_fields'>
            <div className='validate_close_div'>
                <button className='validate_button' onClick={handleSubmit}>Update user</button>
                <button className='close_buton' onClick={onClose}>Close</button>
            </div>
            </div>
 
        </div>
        </div>
    );
};


export default ConnectUserEdit;

const SiteAssigment=({email, userID})=>{

      const [selections, setSelections] = useState([]);
      const [sitesSelections, setSitesSelections] = useState([]);
      const [allSites, setAllsites] = useState([]);
      const [selectedSiteID, setSelectedSiteID] = useState();
      const [filter, setFIlter] = useState(true);
      const formData = {
        email: `${email}`,
    };  
    

    useEffect(()=>{
        apiPost(
                "api/messages/site/users/getSelectedSiteID",
                formData,
                (d)=>{ 
                    console.log('Selected site is ',d['message']['data'][0][0]);
                    const id = d['message']['data'][0][0][0].uss_s_id;
                    //console.log(id);
                    setSelectedSiteID( id);
                },
                (e)=>{
                    console.log("Api error for getselectedsiteid", e);
                    //setError("Error");
                },)
    },[])
    useEffect(()=>{
            apiPost(
                "api/messages/site/users/usersiteslist",
                formData,
                (d)=>{ 
                    console.log('UserSite list ',d['message']['data'][0][0]);
                    setSelections( d['message']['data'][0][0])
                    
                },
                (e)=>{
                    console.log("Api error for usersitelist", e);
                    //setError("Error");
                })
        },[]);

        useEffect(()=>{
                apiPost(
                    "api/messages/user/site/listAll",
                    formData,
                    (d)=>{ 
                        //console.log('Site list ',d['message']['data']);
                        const selectionslist = d['message']['data'].map(s=>{return {"siteID": s.id, "siteName":s.name}});
                        
                        setAllsites(selectionslist)
                        setFIlter(true);
                        setSitesSelections(selectionslist);
                        
                    },
                    (e)=>{
                        console.log("Api error for sitelist", e);
                        //setError("Error");
                    })
                  
        },[])
        
        useEffect(()=>{
            if(filter){
            //Filtering sites to remove selections
            const idsToRemove = new Set(selections.map(item => item.siteID));
            const tmp = allSites.filter(item => !idsToRemove.has(item.siteID));
            //console.log('filters on',tmp);
            setFIlter(false);
            setSitesSelections(tmp);
        }
        },[selections,sitesSelections])
      
      function removeSite(siteID) {
        console.log("removing ",siteID);
        apiPost(
            "api/messages/user/site/remove",
            {
                "userID" : userID,
                "siteID" : siteID,
            },
            (d)=>{ 
                //setSite("");
                // const tmp = selections.filter(item=>item.siteID !== siteID);
                // setFIlter(true);
                // setSelections(tmp);
                // console.log('removing tag',siteID);
                refreshSelections();
                
            },
            (e)=>{ console.log(e) },
        )
    };

        function  setSelectedSite(siteID) {
        apiPost(
            "api/messages/user/site/setSelectedSite",
            {
                "userID" : userID,
                "siteID" : siteID,
            },
            (d)=>{ 
                console.log('Setting selected site ID',siteID);
                setSelectedSiteID(siteID);
                return true;
            },
            (e)=>{ console.log(e); return false; },
        )

    };
    function refreshSelections()
    {
        apiPost(
                "api/messages/site/users/usersiteslist",
                formData,
                (d)=>{ 
                    console.log('UserSite list ',d['message']['data'][0][0]);
                    setFIlter(true);
                    setSelections( d['message']['data'][0][0])
                    console.log(d['message']['data'][0][0]);
                    
                },
                (e)=>{
                    console.log("Api error for usersitelist", e);
                    //setError("Error");
                })
    }
    function addSite(site){
        apiPost(
            "api/messages/user/site/add",
            {
                "userID" : userID,
                "siteID" : site.siteID,
            },
            (d)=>{ 
                refreshSelections();
                // try {
                //     console.log('adding', site)
                //     const tmp = {
                //         siteID: site.siteID,
                //         siteName: site.siteName,

                //     };
                //     setFIlter(true);
                //     if(selections.length===0)
                //         setSelectedSite([tmp]);
                //     else
                //         setSelections([...selections,tmp]);

                //         console.log("lenght",selections.length)
                // }
                // catch(e){
                //     console.log("Error 1", e);
                // }
            },
            (e)=>{ 
                console.log("Error 2", e);
                //onError(e) 
            },
        )
    };
    function handleSelcedSiteChange(e){
        if(selectedSiteID!==e.target.value)
            {
                if(setSelectedSite(e.target.value))
                    setSelectedSiteID(e.target.value);
            }
    }
    function clearAll(){
        try{
        selections.map(item=>removeSite(item.siteID));
        }
        catch(ex){console.log(ex)}
        // for(var i=0; i <selections.length; i++)
        //     {
        //         removeSite(selections[i].siteID);
        //     }
    }
      //.log("OPTIONSSS",selections)
      
      if (selections===undefined) return ("No sites config")
    
      return ( 
        <div >
            <label>
            Selected site:
            <div>
                <select 
                    className='selected_site aqselect'
                    value={selectedSiteID}
                    onChange={handleSelcedSiteChange}
                    >  
                    {selections.map(site=>
                        <option   value={site.siteID}>{site.siteName}</option>
                        
                    )}
                </select>
                </div>
            </label>
            <br/>

          Assigned sites
        <div className="flex-row">
          <div style={{display:"flex", flexDirection:'column'}}>
            <div style={{marginBottom:"5px"}}>
                {selections.map((d) => (
                    d.siteID!=null?
                    <div className="tag-item">
                        <span className="text" >{d.siteName}</span>
                        {/* <img src={DELETE_ICON} onClick={()=>removeSite(d.siteID)} className="close"/>  */}
                        <span onClick={()=>removeSite(d.siteID)} className="close">&#10006;</span>
                    </div>
                    :null
                ))}
            </div>
            {   
            selections.length>1?
            
            <div style={{alignItems:'center', display:'flex', justifyContent:'center'}}> 
                <button className='clear_button' onClick={clearAll}>
                    {/* Clear <span>&#10006;</span> */}
                    Clear <img src={DELETE_ICON} /> 
                </button>
                
            </div>
                :null
            }
            <div>
        
          
          <input
          className="tag_input"
          type="text"
          id="autocomplete-input"
          list="suggestions"
          placeholder="Select a site to add..."
          onChange={(e) => {
              //console.log(sitesSelections);
              const selectedOption = sitesSelections.find(opt => opt.siteName === e.target.value);
              
              if (selectedOption) {
                //if (onAdd) onAdd(selectedOption);
                addSite(selectedOption);
                e.target.value = ''; 
              }
              
            
          }}
        />
         <datalist id="suggestions"> 
          {sitesSelections.map(o => <option key={o.id}  value={o.siteName}>{o.siteName}</option>)}
        </datalist>
        </div>
         
        </div>
       
          </div>
           
          
          
        </div>
    
      );
    }
 export {SiteAssigment};

const SelectInput=({name, value, onChange})=>{

    return(
        <div>
        <select 
            name={name} 
            className='aqselect'
            onChange={onChange}
        >
            <option selected={(value==="Admin")} value="Admin">Admin</option>  
            <option selected={(value==="Manager")} value="Manager">Manager</option>            
            <option selected={(value==="Senior Carer")} value="Senior Carer">Senior Carer</option>
            <option selected={(value==="Carer")} value="Carer">Carer</option>
            <option selected={(value==="Maintenance")} value="Maintenance">Maintenance</option>
        </select>
        </div>
    )
}

const TitleInput=({name, value, onChange})=>{

    return(
        <select 
            name={name} 
            className='aqselect'
            onChange={onChange}
        >
            <option selected={(value==="")} value=""></option>
            <option selected={(value==="Mr")} value="Mr">Mr</option>            
            <option selected={(value==="Mrs")} value="Mrs">Mrs</option>
            <option selected={(value==="Ms")} value="Ms">Ms</option>
        </select>
    )
}


const SelectSite=({name, value, onChange, userID})=>{

    const [sitesList, setSitesList] = useState([]);
    const [selectedSite, setSelectedSite] = useState(value);
    
    function onError(e){

    }

    useEffect(()=>{ if (onChange) onChange(selectedSite); }, [selectedSite])
    useEffect(()=>{

        apiPost(
            "api/messages/user/site/listAll",
            {
                "userID" : `${userID}`,
                "siteID" : "pass1"
            },
            (d)=>{ 
                const data = d.message.data;
                //console.log(data);
                setSitesList(data);
            },
            (e)=>{ onError(e) },
        )

    }, [sitesList.length===0])

    function setSelected(e){
        setSelectedSite(e.target.value);
    }

    return(
        <select 
            name={name} 
            className='aqselect'
            onChange={setSelected}
        >
        
        <option selected={(selectedSite==="")} value=""></option>
        {
        sitesList.map(s=><option selected={(selectedSite===s.id)} value={s.id}>{s.name}</option>)
        }

        </select>
    )
}