/*
component called style template to showcase the basic elements of a page and how they appear as default
*/

import { SpinnerLoaderBars } from "../../components/SpinnerLoader";

// import React from 'react';
export const StyleTemplate = () => {

    return(
    <>
        <h1>Style Guide</h1>
        <h2>Typography</h2>
        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Heading 4</h4>
        <h5>Heading 5</h5>
        <h6>Heading 6</h6>
        <SpinnerLoaderBars />
        <h3>Body Text</h3>
        <p>Body text is the default text style. It should be used for all paragraphs and other blocks of text.</p>
        <h3>Links</h3>
        <p><a href="#">This is a link</a></p>
        <h3>Lists</h3>
        <h4>Unordered List</h4>
        <ul>
            <li>List Item 1</li>
            <li>List Item 2</li>
            <li>List Item 3</li>
        </ul>


        <h4>Ordered List</h4>
        <ol>
            <li>List Item 1</li>
            <li>List Item 2</li>
            <li>List Item 3</li>
        </ol>
        <h2>Buttons</h2>
        <button>Default Button</button>
        <button className="primary">Primary Button</button>
        <button className="secondary">Secondary Button</button>
        <button className="danger">Danger Button</button>
        <h2>Forms</h2>
        <form>
            <label htmlFor="text">Text Input</label>
            <input type="text" id="text" name="text" />
            <label htmlFor="email">Email Input</label>
            <input type="email" id="email" name="email" />
            <label htmlFor="password">Password Input</label>
            <input type="password" id="password" name="password" />
            <label htmlFor="textarea">Textarea</label>
            <textarea id="textarea" name="textarea"></textarea>
            <label htmlFor="select">Select</label>
            <select id="select" name="select">
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
            </select>
            <label htmlFor="checkbox">Checkbox</label>
            <input type="checkbox" id="checkbox" name="checkbox" />
            <label htmlFor="radio">Radio</label>
            <input type="radio" id="radio" name="radio" />
        </form>
        <h2>Tables</h2>
        <table>
            <thead>
                <tr>
                    <th>Column 1</th>
                    <th>Column 2</th>
                    <th>Column 3</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Row 1, Cell 1</td>
                    <td>Row 1, Cell 2</td>
                    <td>Row 1, Cell 3</td>
                </tr>
                <tr>
                    <td>Row 2, Cell 1</td>
                    <td>Row 2, Cell 2</td>
                    <td>Row 2, Cell 3</td>
                </tr>
                <tr>
                    <td>Row 3, Cell 1</td>
                    <td>Row 3, Cell 2</td>
                    <td>Row 3, Cell 3</td>
                </tr>
            </tbody>
        </table>
        <h2>Images</h2>
        <img src="https://via.placeholder.com/150" alt="Placeholder Image" />


        
    </>
    );
}

export default StyleTemplate;