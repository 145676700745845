import { PropagateLoader  } from 'react-spinners';
import './spinner.css';

const SpinnerLoader=({isLoading})=> {
    return (
        <div className="w-full max-h-100 spinner-container text-center">
            <PropagateLoader color="#36D7B7" loading={isLoading} />
        </div>
    );
}


export const SpinnerLoaderBars=()=> {
    return (
        <div class="middle">
            <div class="bar bar1"></div>
            <div class="bar bar2"></div>
            <div class="bar bar3"></div>
            <div class="bar bar4"></div>
            <div class="bar bar5"></div>
            <div class="bar bar6"></div>
            <div class="bar bar7"></div>
            <div class="bar bar8"></div>
        </div>
    );
}

export default SpinnerLoader;   