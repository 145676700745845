// PageLoader.js
import React from 'react';

const PageLoader = () => {
  return (
    <div className="page-loader">
      <p>Loading...</p>
    </div>
  );
};

export default PageLoader;