/*
Component for sense histort page called SiteSenseHistoryPage
the page will be a grid template with a pie chart in the top left corner
and then a mui table in the bottom underneath this with the data in table form with the pagination option
and the ability to export the data to a csv file
*/

//importing the required dependencies and components
import { useState, useEffect } from "react";
import PageLoader from "../../../components/Loaders/PageLoader";
import SiteSenseHistoryTable from "./SenseHistoryTable";
import { PieChart } from "@mui/x-charts";

//component for SiteSenseHistoryPage
const SiteSenseHistoryPage = ({data}) => {
    //setting the initial state of the component
    const [chartData, setChartData] = useState([]);
    const [tableData, setTableData] = useState([]);
    
    //function to calculate duration in seconds from the start and end time
    const calculateDuration = (start, end) => {
        let startDate = new Date(start);
        let endDate = new Date(end);
        return (endDate - startDate) / 1000;
    }

    useEffect(() => {
        console.log("data", data);
        if(data && data.length > 0) {
            let chartData = [];
            let tableData = [];
            data.forEach((item) => {
                chartData.push({
                    name: item.status,
                    value: calculateDuration(item.start_date, item.end_date) 
                });
            });

            //group chart data by name and sum the values
            let cData = chartData.reduce((acc, item) => {
                if(!acc[item.name]) {
                    acc[item.name] = 0;
                }
                acc[item.name] += item.value;
                return acc;
            }, {});
            
            //convert the object to an array
            cData = Object.keys(cData).map((key) => {
                return {
                    label: key,
                    value: cData[key]
                    //color: "red"
                }
            });

            console.log("chartData", cData);


            setChartData(cData);
        }
    }, [data]);
    
    
    //returning the jsx
    return (
        <div
        //className="flex-row" 
        className="senseHistoryGridLayout"
        >
            <div className="pie_container">
                <PieChart 
                series={[
                    {
                      data: chartData,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    valueFormatter: valueFormatter,
                    },
                  ]}
                  //onClick={handleClick}
                  width={400}
                  height={200}
                  margin={{ right: 200 }}
//                  onClick={(event, item) => handleItemClick(data, item)}
                />
            </div>
            <div className="data_table_container">
                <SiteSenseHistoryTable reportData={data}  />
            </div>
        </div>
    );
}        


export const valueFormatter = (item) => `${secondsToHms(item.value)}%`;

//function to convert seconds to hh:mm:ss format
export const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
}

export default SiteSenseHistoryPage;
