/*
Component called SiteSenseHistoryTable
mui table
data will be passed in as a prop
*/

//importing the required dependencies and components
import { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { createMRTColumnHelper } from 'material-react-table';
import {
  MaterialReactTable,
  MRT_EditActionButtons,
  useMaterialReactTable,
  MRT_ColumnDef
} from 'material-react-table';
import { interpolateCubehelixDefault } from "d3";

const columnHelper = createMRTColumnHelper();

//component for SiteSenseHistoryTable
const SiteSenseHistoryTable = ({ reportData }) => {
  //setting the initial state of the component
  const [data, setData] = useState([]);

  const _tableHeaderFontSize = "12.5px";
  const _tableBodyFontSize = "12.5px";


  const columns = [


    columnHelper.accessor('dv_deviceName', {
      header: 'Room',
      size: 40,
    }),

    columnHelper.accessor('deviceZone', {
      header: 'Zone',
      size: 40,
    }),

    columnHelper.accessor('status', {
      header: 'Location',
      size: 40,
    }),

    /*
    columnHelper.accessor('device_code', {
      header: 'device_code',
      size: 40,
    }),

    columnHelper.accessor('device_path', {
      header: 'device_path',
      size: 40,
    }),


    columnHelper.accessor('dv_deviceType', {
      header: 'dv_deviceType',
      size: 40,
    }),

    columnHelper.accessor('dv_id', {
      header: 'dv_id',
      size: 40,
    }),
    
    columnHelper.accessor('dv_siteID', {
      header: 'dv_siteID',
      size: 40,
    }),
*/
columnHelper.accessor('start_date', {
  header: 'start_date',
  size: 40,
}),

    columnHelper.accessor('end_date', {
      header: 'end_date',
      size: 40,
    }),
  /*  
    columnHelper.accessor('id', {
      header: 'id',
      size: 40,
    }),

    columnHelper.accessor('siteID', {
      header: 'siteID',
      size: 40,
    }),
    */
    

    /*
            columnHelper.accessor('device_type', {
            //header: 'Device type',
            size: 40,
            }),
            columnHelper.accessor('unit_id', {
            header: 'Unit ID',
            size: 40,
            }),
            columnHelper.accessor('unit_name', {
            header: 'Unit name',
            size: 40,
            }),
            columnHelper.accessor('unit_zone', {
            header: 'Unit zone',
            size: 40,
            }),
      */
  ];
  const TableSettings = {
    selectAllMode: "all",
    enableRowActions: true,
    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    // paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    positionActionsColumn: 'last',
    enableFullScreenToggle: false,
    //manualSorting: true,  //this caused the filtering to stop on the pages
    muiTableHeadCellProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example
      sx: {
        fontWeight: 'bold',
        fontSize: _tableHeaderFontSize,
        color: "gray"
      },
    },

    muiTableBodyRowProps: ({ row }) => ({
      //conditionally style selected rows
      sx: {
        fontWeight: row.getIsSelected() ? 'bold' : 'normal',
        fontSize: _tableBodyFontSize,
        color: "gray"
      },
    }),
    muiTableBodyCellProps: ({ column }) => ({
      //conditionally style pinned columns
      sx: {
        fontWeight: column.getIsPinned() ? 'bold' : 'normal',
        fontSize: _tableBodyFontSize,
        FontFace: "sans-serif",
        border: 0,
        color: "gray"
      },
    }),
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '', //change header text
        //size: 300, //make actions column wider
      },
    },
    muiTableBodyProps: {
      /*
      ADC to go live
      sx: {
        //stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(odd) > td': {
          backgroundColor: '#f5f5f5',
        },
      },
      */
    },
    muiBottomToolbarProps: {

    },

    initialState: {
      pagination: {
        pageSize: 50,
        pageIndex: 0, // Optional: you can set the initial page index if needed
      },
    },


    muiPaginationProps: {
      // defaultPage:{ }


      rowsPerPageOptions: [10, 25, 50, 100],
      // showFirstButton: true,
      // showLastButton: true,
      // color: 'primary',
      // shape: 'rounded',
      showRowsPerPage: true,
      // count: {global_rowCount}
      // variant: 'outlined',

      sx: {
        fontSize: '15px',
        button: {
          fontWeight: 'bold',
          fontSize: "15px",
        },
        backgroundColor: '#f5f5f5',
      },

    },
  };
  //    renderTopToolbarCustomActions: ({ table }) => (<ExportButton tableData={table} />),

  useEffect(() => {
    setData(reportData);
  }, [reportData]);

  console.log("tableData", data);
  const table = useMaterialReactTable({ columns, data, initialState: { pagination: { pageSize: 50, pageIndex: 0 } }, ...TableSettings });


  //returning the jsx
  return (
    <MaterialReactTable table={table} />
  );
}

export default SiteSenseHistoryTable;
