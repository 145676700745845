import {
  MaterialReactTable,
  MRT_EditActionButtons,
  useMaterialReactTable,
  MRT_ColumnDef
} from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here
import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  Menu,
  IconButton,
} from '@mui/material';
import { AccountCircle, Send, Edit, Key, Link, Mail, DisplaySettings, Info, Settings } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import AQModal from '../AQModal/AQModal';
import { useState } from 'react';
import EditZoneProfile from '../EditZoneProfile/EditZoneProfile';
import NewZoneProfilePage from '../NewZoneProfilePage/NewZoneProfilePage';
import NewUserPage from '../NewUserPage/NewUserPage';
import NewIntegration from '../../Pages/Settings/Integration/NewIntegration';
import CreateAPI from '../../Pages/Settings/Integration/CreateAPI';
import NewGroups from '../../Pages/Settings/Groups/NewGroups';
import EditGroup from '../../Pages/Settings/Groups/EditGroups';
import DeleteGroup from '../../Pages/Settings/Groups/DeleteGroups';
import ResetPasswordForm from '../ResetPasswordForm/ResetPasswordForm';
import { apiPost } from '../../data/apidata';
import UserDetails from '../UserDetails/UserDetails';
import ExportHandler from '../../Pages/Exports/export_handle';
import ConnectUserEdit from '../../Pages/Settings/UserManagement/connectUserEdit';
import { useSnackbar } from 'notistack';
import AQToolTip from '../ToolTip/ToolTip';
// import Link from '@mui/icons-material';
// import Menu from '@mui/material';
import { NourishResidentEdit } from '../../Pages/Nourish/NourishResidentEdit';
import { NourishResidentDetails } from '../../Pages/Nourish/NourishResidentDetails';
import { UserDetailsResident } from './ResidentsTable';
import { createMRTColumnHelper } from 'material-react-table';


import UserEdit from '../../Pages/Teams/UserEdit/UserEdit';

import NewSitePage from '../SiteManagement/NewSitePage';
import { SiteRoomConfig } from '../SiteManagement/SiteRoomConfig';
import { SiteDetails } from '../SiteManagement/SiteDetails';
import EditSiteDetails from '../SiteManagement/EditSiteDetails';
import EditSiteConfig from '../SiteManagement/EditSiteConfig';
import ArquellaResidentNew from '../../Pages/Residents/ArquellaResidentNew';
import { EditCallConfig } from '../CallConfig/EditCallConfig';
import { SenseConfig } from '../Sense/SenseConfig';
import DeleteZoneProfileHandler from '../NewZoneProfilePage/DeleteZoneProfile'; import { SiteConfigSelector } from '../SiteManagement/SiteConfigSelector';
import { EditSenseAllDevices } from '../Sense/EditSenseAllDevices';
import CareClicksField from '../CareClicksField/CareClicksField';
import { JourneyBar } from '../charts/JourneyBar/JourneyBar';

const columnHelper = createMRTColumnHelper();

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const _tableHeaderFontSize = "12.5px";
const _tableBodyFontSize = "12.5px";



const AQDataTable = ({ data, columns, settingsType, onUpdateRefreshPath, dataRefresh, siteID, options }) => {

  const [rowCount, setRowCount] = useState(0);

  function getCurrentDateTimeForFilename() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${year}_${month}_${day}_${hours}_${minutes}`;
  }

  function getFilename(section) {
    return `${getTitleFromSettingsType(settingsType)}_${getCurrentDateTimeForFilename()}`;
  }

  const [modalElement, setModalElement] = useState(null);
  const [modalHeight, setModalHeight] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleExportRows = (rows) => {

    function runDownload(exdata) {
      csvConfig["filename"] = getFilename("");
      const csv = generateCsv(csvConfig)(cleanData(exdata, settingsType));
      download(csvConfig)(csv);
    }

    const rowData = rows.map((row) => row.original);
    if ((rows !== null) && (rows.length > 0)) {
      runDownload(rowData, settingsType);
    }
    else {
      runDownload(data, settingsType);
    }

  };

  function cleanData(data, tableType) {

    if (tableType === "settings_groups") {
      return data.map(d => {
        return {
          "Group Name": d.groupName,
          "Group ID": d.groupID,
          'Group Units': d.groupUnit
        }
      });
    }

    if (tableType === "settings_integrations") {
      return data.map(d => { return { "Integration": d.name, "API Key": d.apikey } });
    }

    if (tableType === "team_management") {
      return data.map(d => { return { "Title": d.title, "First name": d.firstName, "Surname": d.surname, "Email": d.email, "Role": d.role, } });
    }

    if (tableType === "live_calls") {
    
      const rtnData = data.map(d => {
        return {
          "Call type": d.callType,
          "Start date": d.start,
          "End date": d.end,
          "Duration": d.duration,
          "Room": d.room,
          "Zone": d.Zone ? d.Zone : d.zone,
          "Carer": d.Carer,
          "Care delivered": getCareText(d.care_delivered)
        }
      });
      
      return rtnData;

    }

    if (tableType === "reporting_data") {
      return data.map(d => {
        return {
          "Call type": d.callType,
          "Start date": d.start,
          "End date": d.end,
          "Duration": d.duration,
          "Room": d.Room,
          "Zone": d.Zone ? d.Zone : d.zone,
          "Carer": d.Carer,
          "Care delivered": getCareText(d.care_delivered),
        }
      });
    }

    return data;
  }

  function getCareText(care) {
    //(d.care_delivered.length===0) ? "" : d.care_delivered.map(c=>(c["CallType"]==="Comments")?c["callValue  "]:c["CallType"]).join("-")
    return care;
  }


  const handleExportData = () => {
    csvConfig["filename"] = getFilename("");
    const csv = generateCsv(csvConfig)(cleanData(data, settingsType));
    download(csvConfig)(csv);
  };

  const defaultSettings = {
    ...MasterTableSettings,
    renderTopToolbarCustomActions: ({ table }) => (<ExportButton tableData={table} />),
  }

  const TopMenu = (props) => {
    return (<div className='flex-row'>{props.children}<ExportButton tableData={props.tableData} /> </div>);
  }

  function addDefault(obj) { return { ...defaultSettings, ...obj } }


  function getTableSettings(strSettings) {

    if (strSettings === "live_calls") return addDefault(table_settings_live_calls);
    if (strSettings === "devices") return addDefault(table_settings_devices);
    if (strSettings === "user_management") return addDefault(table_settings_user_management);
    if (strSettings === "team_management") return addDefault(table_settings_team_management);
    if (strSettings === "settings_integrations") return addDefault(table_settings_integrations);
    if (strSettings === "settings_groups") return addDefault(table_settings_groups);
    if (strSettings === "reporting_data") return addDefault(table_settings_reporting_calls);
    if (strSettings === "zone_profile") return addDefault(table_settings_zone_profile);
    if (strSettings === "zone_profile_zones") return addDefault(table_settings_zone_profile_zones);
    if (strSettings === "residents_table") return addDefault(table_residents_list);
    if (strSettings === "site_management") return addDefault(table_site_management);
    if (strSettings === "call_config") return addDefault(table_call_config);
    if (strSettings === "sense_page") return addDefault(table_sense_page);
    return defaultSettings;
  }

  function getTitleFromSettingsType(strSettings) {

    if (strSettings === "live_calls") return "Todays_Activity";
    if (strSettings === "devices") return "Devices";
    if (strSettings === "user_management") return "User_Management";
    if (strSettings === "site_management") return "Site_Management";
    if (strSettings === "team_management") return "Team_Management";
    if (strSettings === "settings_integrations") return "Integrations";
    if (strSettings === "settings_groups") return "Groups";
    if (strSettings === "reporting_data") return "Reports";
    if (strSettings === "zone_profile") return "Zone_Profile";
    if (strSettings === "zone_profile_zones") return "Zone_Profile";
    if (strSettings === "sense_page") return "sense_page";
    return "connect_export";
  }

  const table_settings_zone_profile_zones = {
    enableRowActions: false,
    enableRowSelection: false,
    enablePagination: false,
    enableColumnFilters: false,
    enableSorting: false,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    positionActionsColumn: 'last',
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableHiding: false,
    enableGlobalFilter: false,
    initialState: { columnVisibility: { id: false } },
    renderTopToolbarCustomActions: ({ table }) => (
      <div className='flex-row'>
        <TopMenu tableData={table}>
          {/*<button className='button_aqtable'>Add Zone</button>*/}
        </TopMenu>
      </div>),
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [rowID, setRowID] = useState(null);

  const handleMenuOpen = (event, id) => {
    setAnchorEl(event.target);
    setRowID(id)
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setRowID(null);
  };
  function isNHSValid(nhs) {
    return nhs.length === 10 && !isNaN(nhs) && nhs.trim() === nhs;
  }
  const table_residents_list = {

    ...MasterTableSettings,
    renderRowActions: ({ row,
      //closeMenu 
    }) => (
      <div className='buttonLayout'>
        <AQToolTip className="menuButton" tooltip={<div className="tooltipStyle">View In Nourish</div>} >
          <IconButton className='menuButton'
            color="primary"
            onClick={() => window.open(row.original.clientSystemUrl)}
          >
            <Link style={{ color: 'gray' }} />
          </IconButton>
        </AQToolTip>
        <IconButton color='primary' className=' menuButton' onClick={(t) => handleMenuOpen(t, row.original.id)}>
          <svg className='ThreeDots' color='#383636' class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="MoreHorizIcon"><path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2"></path></svg>
          {/* <p className='ThreeDots'>...</p> */}
        </IconButton>

        <Menu
          className="ActionMenuShadow" i
          anchorEl={anchorEl}
          open={Boolean(rowID === row.original.id)}
          onClose={handleMenuClose}
        >
          <MenuItem
            key={0}
            onClick={() => {
              handleMenuClose();
              // setModalElement(<UserDetails onClose={closeModal}  user={row.original} siteID={siteID} dataRefresh={dataRefresh} options={options}/>)
              setModalElement(<NourishResidentDetails onClose={closeModal} userData={row.original} siteID={siteID} dataRefresh={dataRefresh} options={options} />)

            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon><AccountCircle /></ListItemIcon>
            View Details
          </MenuItem>

          <MenuItem
            key={0}
            onClick={() => {
              // setSelectedResident(row.original);
              handleMenuClose();
              setModalElement(<NourishResidentEdit onClose={closeModal} userData={row.original} siteID={siteID} dataRefresh={dataRefresh} options={options} />)
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon><Edit /></ListItemIcon>
            Edit Details
          </MenuItem>

          <MenuItem
            key={0}
            onClick={() => {
              // setSelectedResident(row.original);
              handleMenuClose();
              setModalElement(<UserDetailsResident onClose={closeModal} user={row.original} siteID={siteID} dataRefresh={dataRefresh} options={options} />)
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon><Edit /></ListItemIcon>
            Edit old
          </MenuItem>
        </Menu>
      </div>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className='flex-row'>
        <button
          className='button_aqtable'
          onClick={() => {
            setModalElement(<ArquellaResidentNew onClose={closeModal} siteID={siteID} dataRefresh={dataRefresh} options={options} />);
          }}
        >Create new user</button>&nbsp;
        <ExportButton tableData={table} />
      </div>),
  };

  const table_settings_devices = {
    ...defaultSettings,
    initialState: { columnVisibility: { move_id: false, groupid: false }, pagination: { pageSize: 50, pageIndex: 0 } },
    muiTableHeadCellProps: { sx: { fontWeight: 'bold', fontSize: _tableHeaderFontSize, }, },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '', //change header text
        //size: 300, //make actions column wider
      },
    },
    /*
      renderRowActionMenuItems: ({ closeMenu }) => [
        <>
        <MenuItem
          key={0}
          onClick={() => {
            // View profile logic...
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          View Device
        </MenuItem>,
        </>
      ],
    */
    renderTopToolbarCustomActions: ({ table }) => (
      <div className='flex-row'>
        {/*<button className='button_aqtable'>Add Device</button>&nbsp;*/}
        <ExportButton tableData={table} />
      </div>),

  };

  const table_settings_integrations = {
    ...defaultSettings,
    initialState: { columnVisibility: { id: false }, pagination: { pageSize: 50, pageIndex: 0 } },
    renderRowActionMenuItems: ({ closeMenu }) => [
      <>
        <MenuItem
          key={0}
          onClick={() => {
            setModalElement(<CreateAPI onClose={closeModal} data={data} />);
            closeMenu();
          }}
          sx={{ m: 0 }}>
          <ListItemIcon>
            <Key />
          </ListItemIcon>
          Generate New API Key
        </MenuItem>
      </>
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      setRowCount(table),
      <div className='flex-row'>
        <button
          className='button_aqtable'
          onClick={() => {
            setModalElement(<NewIntegration onClose={closeModal} data={data} siteID={siteID} />);
          }}
        >Create new integration</button>&nbsp;
        <ExportButton tableData={table} />
      </div>)

  };

  const table_settings_groups = {
    ...defaultSettings,
    initialState: { columnVisibility: { id: false }, pagination: { pageSize: 50, pageIndex: 0 } },
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <>
        <MenuItem
          key={0}
          onClick={() => {
            setModalElement(<EditGroup data={data[row.id]} onClose={closeModal} siteID={siteID} />);
            closeMenu();
          }}
          sx={{ m: 0 }}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          Edit group
        </MenuItem>
        <MenuItem
          key={0}
          onClick={() => {
            setModalElement(<DeleteGroup data={data[row.id]} onClose={closeModal} />);
            closeMenu();
          }}
          sx={{ m: 0 }}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete group
        </MenuItem>
      </>
    ],

    renderTopToolbarCustomActions: ({ table }) => (
      <div className='flex-row'>
        <button
          className='button_aqtable'
          onClick={() => {
         
            setModalElement(<NewGroups onClose={closeModal} data={data} siteID={siteID} />);
          }}
        >Create new group</button>&nbsp;
        <ExportButton tableData={table} />
      </div>)
  };

  const table_settings_team_management = {
    ...defaultSettings,
    initialState: { columnVisibility: { id: false, siteID: false, groupid: false }, pagination: { pageSize: 50, pageIndex: 0 } },
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <>
        <MenuItem
          key={1}
          onClick={() => {

            setModalElement(<UserEdit onClose={closeModal} siteID={siteID} userData={data[row.id]} />);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          Edit details
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => {
            setModalElement(<ResetPasswordForm id={data[row.id].email} onClose={closeModal} />);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <Send />
          </ListItemIcon>
          Reset Password
        </MenuItem>,

        <MenuItem
          key={0}
          onClick={() => {
            setModalHeight("200px")
            setModalElement(<DeleteDialog name={data[row.id].surname} email={data[row.id].email} userID={data[row.id].id} onClose={closeModal} />)
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>,
      </>
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      <div className='flex-row'>
        <button
          className='button_aqtable'
          onClick={() => {
            setModalElement(<NewUserPage onClose={closeModal} siteID={siteID} canAssign={false} />);
          }}
        >Create new user</button>&nbsp;
        <ExportButton tableData={table} />
      </div>),
  };

  const table_settings_user_management = {
    ...defaultSettings,
    initialState: { columnVisibility: { id: false, siteID: false, groupid: false }, pagination: { pageSize: 50, pageIndex: 0 } },
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <>
        <MenuItem
          key={0}
          onClick={() => {
            setModalElement(<UserDetails id={data[row.id].email} details={data[row.id]} onClose={closeModal} />);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          View Details
        </MenuItem>

        <MenuItem
          key={1}
          onClick={() => {

            setModalElement(<ConnectUserEdit onClose={closeModal} siteID={siteID} userData={data[row.id]} />);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          Edit details
        </MenuItem>

        <MenuItem
          key={1}
          onClick={() => {
            setModalElement(<ResetPasswordForm id={data[row.id].email} onClose={closeModal} />);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <Send />
          </ListItemIcon>
          Reset Password
        </MenuItem>
        <MenuItem
          key={3}
          onClick={() => {
            //deleteUser(data[row.id].email);
            setModalHeight("200px")
            setModalElement(<DeleteDialog name={data[row.id].surname} userID={data[row.id].id} email={data[row.id].email} onClose={closeModal} />)
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>

        <MenuItem
          key={4}
          onClick={() => {
            //deleteUser(data[row.id].email);
            setModalHeight("200px")
            setModalElement(<SendResetLink name={data[row.id].surname} userID={data[row.id].id} email={data[row.id].email} onClose={closeModal} />)
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <Mail />
          </ListItemIcon>
          Send new password link
        </MenuItem>
      </>
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      <div className='flex-row'>
        <button
          className='button_aqtable'
          onClick={() => {
            setModalElement(<NewUserPage onClose={closeModal} siteID={null} canAssign={true} />);
          }}
        >Create new user</button>&nbsp;
        <ExportButton tableData={table} />
      </div>),
  };
  const table_call_config = {
    ...defaultSettings,
    initialState: { columnVisibility: { id: false, siteID: false, groupid: false }, pagination: { pageSize: 50, pageIndex: 0 } },
    // renderRowActionMenuItems: ({ row, closeMenu }) => [
    //   <>

    //   </>
    // ],
    renderTopToolbarCustomActions: ({ table }) => (
      <div className='flex-row'>
        <button
          className='button_aqtable'
          onClick={() => {
            setModalElement(<EditCallConfig onClose={closeModal} />);
          }}
        >Edit call configuration</button>&nbsp;
        {/* <ExportButton tableData={table} /> */}
      </div>),
  };

  const table_site_management = {
    ...defaultSettings,
    initialState: { columnVisibility: { id: false, siteID: false, groupid: false }, pagination: { pageSize: 50, pageIndex: 0 } },
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <>
        <MenuItem
          key={0}
          onClick={() => {
            setModalElement(<SiteDetails id={data[row.id].site_id} onClose={closeModal} />);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          Site details
        </MenuItem>

        <MenuItem
          key={0}
          onClick={() => {
            setModalElement(<EditSiteDetails siteID={data[row.id].site_id} siteList={data} onClose={closeModal} />);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          Edit site details
        </MenuItem>
        <MenuItem
          key={0}
          onClick={() => {
            setModalElement(<SiteConfigSelector option={'edit'} setModal={setModalElement} siteID={data[row.id].site_id} siteList={data} onClose={closeModal} />);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DisplaySettings />
          </ListItemIcon>
          Edit config
        </MenuItem>

        <MenuItem
          key={0}
          onClick={() => {
            setModalElement(<SiteConfigSelector option={'create'} setModal={setModalElement} siteID={data[row.id].site_id} siteList={data} onClose={closeModal} />);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          Setup services
        </MenuItem>
        {/* <MenuItem
          key={0}
          onClick={() => {
            setModalElement(<EditSiteConfig siteID={data[row.id].site_id} siteList={data} onClose={closeModal} />);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DisplaySettings />
          </ListItemIcon>
          Edit site config
        </MenuItem>
        <MenuItem
          key={0}
          onClick={() => {
            setModalElement(<SenseConfig siteID={data[row.id].site_id} onClose={closeModal} />);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DisplaySettings />
          </ListItemIcon>
          Edit sense config
        </MenuItem> */}
      </>
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      <div className='flex-row'>
        <button
          className='button_aqtable'
          onClick={() => {
            setModalElement(<NewSitePage siteList={data} onClose={closeModal} />);
          }}
        >Create new site</button>&nbsp;
        {/* <ExportButton tableData={table} /> */}
      </div>),
  };


  const table_sense_page = {
    ...defaultSettings,
    initialState: { columnVisibility: { id: false, siteID: false, groupid: false }, pagination: { pageSize: 50, pageIndex: 0 } },
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <>

        <MenuItem
          key={0}
          onClick={() => {
            setModalElement(<EditSenseAllDevices siteID={data[row.id].site_id} siteList={data} onClose={closeModal} />);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DisplaySettings />
          </ListItemIcon>
          Edit site config
        </MenuItem>

      </>
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      <div className='flex-row'>
        <button
          className='button_aqtable'
          onClick={() => {
            setModalElement(<NewSitePage siteList={data} onClose={closeModal} />);
          }}
        >Create new site</button>&nbsp;
        {/* <ExportButton tableData={table} /> */}
      </div>),
  };

  const table_settings_zone_profile = {
    initialState: {
      showColumnFilters: true,
      columnVisibility: { id: false },
      pagination: { pageSize: 50, pageIndex: 0 }
    },
    enableFullScreenToggle: false,
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <>

        <MenuItem
          key={0}
          onClick={() => {
            // View profile logic...

            setModalElement(
              <NewZoneProfilePage siteCode={siteID} onClose={closeModal} profileData={data[row.id]}></NewZoneProfilePage>
            )

            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Edit
        </MenuItem>
        ,
        <MenuItem
          key={0}
          onClick={() => {
            // View profile logic...
   
            DeleteZoneProfileHandler(data[row.id].id).then((d) => {
              enqueueSnackbar(d, { variant: 'success' });
              if (dataRefresh) dataRefresh();
            }).catch((e) => {
              enqueueSnackbar(e, { variant: 'error' });
            })
              .finally(() => {
                closeMenu();
              });

          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Delete

        </MenuItem>

      </>
    ],
    enableRowSelection: false,
    renderTopToolbarCustomActions: ({ table }) => (
      <TopMenu tableData={table}>
        <button
          onClick={() => {
            setModalElement(
              <NewZoneProfilePage siteCode={siteID} onClose={closeModal}></NewZoneProfilePage>
            );
          }}
          className='button_aqtable'>Create Zone Profile</button>

      </TopMenu>
    ),
  };

  const table_settings_reporting_calls = {
    initialState: {
      showColumnFilters: true,
      columnVisibility: { AQRef: false, recID: false, JourneyRef: false, SiteID: false, Site: false, duration: true },
      pagination: { pageSize: 50, pageIndex: 0 }
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '', //change header text
        //size: 300, //make actions column wider
      },
    },
    enableRowSelection: true,
    renderTopToolbarCustomActions: ({ table }) => (<ExportButton tableData={table} />),
  };

  const table_settings_live_calls = {
    ...defaultSettings,
    initialState: { pagination: { pageSize: 50, pageIndex: 0 } },
    renderTopToolbarCustomActions: ({ table }) => (<ExportButton tableData={table} />),
  };

  const IconExport = () => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15" /></svg>

  /*function getProcessor(val){
    if (val==="") return "";
    return val;
  }*/

  const ExportButton = ({ tableData }) => {

    const [exportData, setExportData] = useState(null);
 
    return (
      <>
        {
          (exportData !== null) &&
          <ExportHandler title={"data"} data={exportData} onClose={() => setExportData(null)} masterData={data} show={(exportData !== null)} processor={settingsType} />
        }
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            padding: '8px',
            flexWrap: 'wrap',
          }}
        >
          <Button
            onClick={() => {
              const rows = ((tableData.getIsAllRowsSelected()) ? tableData.getSelectedRowModel().rows : tableData.getSelectedRowModel().rows);
              const rowData = rows.map((row) => row.original);
              if ((rows !== null) && (rows.length > 0)) {
                setExportData(cleanData(rowData));
              }
              else {
                setExportData(cleanData(data));
              }
              //handleExportRows((tableData.getIsAllRowsSelected())?tableData.getSelectedRowModel()  .rows:tableData.getSelectedRowModel().rows)
            }}
            //onClick={() => handleExportRows((tableData.getIsAllRowsSelected())?tableData.getSelectedRowModel()  .rows:tableData.getSelectedRowModel().rows)}
            disabled={tableData.getSelectedRowModel().rows.length === 0}
            startIcon={<FileDownloadIcon />}
          ></Button>
        </Box>
      </>
    );
  }

  const columns_template_reporting_calls = [
    // columnHelper.accessor('callType', {
    //   header: 'Call Origin',
    //   size: 40,
    //   filterVariant: 'multi-select',
    //   filterSelectOptions: ["Emergency", "Call", "Attendance", "Assistance", "Sense"],
    // }),
    
    columnHelper.accessor('DeviceType', {
      header: 'Call Sourcesss',
      size: 40,
      filterVariant: 'multi-select',
      filterSelectOptions: ["Room Unit", "Pendant", "Motion Point", "Sensor Mat Unit", "Doorbell", "Ceiling Pull Cord", "Door Contact", "Sense"],
    }),
    columnHelper.accessor('callText', {
      header: 'Call Types',
      size: 40,
      filterVariant: 'multi-select',
      filterSelectOptions: ["Emergency", "Call", "Attendance", "Assistance", "Sense"],
    }),
    columnHelper.accessor('room', {
  //accessorKey: 'roomsss',
      header: 'Room',
      size: 40,
  //    filterVariant: 'multi-select',
  //    filterSelectOptions: ["Bedroom 15", "Bedroom 8", "Bedroom 4", "Bedroom 1", "Bedroom 2"],
    }),
  
    columnHelper.accessor('zone', {
      header: 'Zone',
      size: 40,
    }),
  
    columnHelper.accessor('calls', {
      header: 'Journey ',
      size: 150,
      Cell: ({ renderedCellValue, row }) =><JourneyBar journey={renderedCellValue} totalSeconds={row.original.durationSeconds} />,
    }),
  
    columnHelper.accessor('start', {
      header: 'Start',
      size: 100,
      Cell: ({ cell }) =><div>{utcDateToHHMMYYYYHHMM(cell.getValue())}</div>,
    }),
    
    columnHelper.accessor('end', {
      header: 'End',
      size: 100,
      Cell: ({ cell }) =><div>{utcDateToHHMMYYYYHHMM(cell.getValue())}</div>
    }),
  
    columnHelper.accessor('duration', {
      header: 'Duration',
      size: 40,
    }),
  
    columnHelper.accessor('Carer', {
      header: 'Carer',
      size: 40,
      Cell: ({ renderedCellValue }) =><div>{renderedCellValue}</div>
    }),
    columnHelper.accessor('AQRef', {
      header: 'AQRef',
      size: 40,
    }),
    columnHelper.accessor('JourneyRef', {
      header: 'JourneyRef',
      size: 40,
    }),
    columnHelper.accessor('SiteID', {
      header: 'SiteID',
      size: 40,
    }),
    columnHelper.accessor('Site', {
      header: 'Site',
      size: 40,
    }),
    columnHelper.accessor('care_delivered', {
      header: 'Care Delivered',
      size: 40,
      Cell: ({ renderedCellValue, row }) => (
        <div><CareClicksField careClicks={renderedCellValue} /></div>
      ),
    }),
  ];
  
  
  //if (strSettings === "reporting_data") return columns;
  
  console.log("colTemplate", columns);
  
  const table = useMaterialReactTable({ columns , data, initialState: { pagination: { pageSize: 50, pageIndex: 0 } }, ...getTableSettings(settingsType) });

  function DeleteDialog({ name, email, userID, onClose }) {
    return (
      <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
        <div className='aqForm' style={{ height: '180px' }}>
          <h3>
            Delete User
          </h3>
          <div>
            Are you sure you want to delete {email}'s account?<br />
            This action cannot be undone.
          </div>
          <div className='submit_fields'>
            <div>
              <button className='clear_button' onClick={() => deleteUser(email, userID)}>Delete</button>
              <button className='close_buton' onClick={onClose} >Close</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  function deleteUser(username, userID) {
    if (username === "") return;
    apiPost("api/messages/user/delete",
      {
        "username": username,
        "userID": userID,
      },
      (d) => {
        if (dataRefresh) dataRefresh();

        enqueueSnackbar("User deleted successfully", { variant: "success" });
        closeModal();
      },
      (e) => { console.log(e) }
    )
  }

  function SendResetLink({ name, email, userID, onClose }) {

    return (
      <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
        <div className='aqForm' style={{ height: '180px' }}>
          <h3>
            Send Reset link
          </h3>
          <div>
            Are you sure you want send a new reset password link to {email}'s account?<br />
            This action cannot be undone.
          </div>
          <div className='submit_fields'>
            <div>
              <button className='validate_button' onClick={() => sendResetToUser(email, userID)}>Send link</button>
              <button className='close_buton' onClick={onClose} >Close</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  function sendResetToUser(username, userID) {
    if (username === "") return;
    apiPost("api/messages/user/email/resetPassword",
      {
        "email": username,
        "userID": userID,
      },
      (d) => {
        if (dataRefresh) dataRefresh();
        enqueueSnackbar("Password link successfully sent", { variant: "success" });
        closeModal();
      },
      (e) => {
        console.log(e)
        enqueueSnackbar(`Error ${e}`, { variant: "error" });
      }
    )
  }

  function closeModal() {
    setModalHeight(null)
    setModalElement(null);
    if (onUpdateRefreshPath !== undefined) {
      if (dataRefresh) dataRefresh();
    }
    else {
      if (dataRefresh) dataRefresh();
    }
  }

  
  return (
    <>
      {
        (modalElement !== null)
        &&
        <AQModal show={true} onClose={closeModal} height={modalHeight}>
          {modalElement}
        </AQModal>
      }
      <MaterialReactTable table={table} />
    </>
  );

};


export const MasterTableSettings = {
  selectAllMode: "all",
  enableRowActions: true,
  enableRowSelection: true,
  columnFilterDisplayMode: 'popover',
  // paginationDisplayMode: 'pages',
  positionToolbarAlertBanner: 'bottom',
  positionActionsColumn: 'last',
  enableFullScreenToggle: false,
  //manualSorting: true,  //this caused the filtering to stop on the pages
  muiTableHeadCellProps: {
    //simple styling with the `sx` prop, works just like a style prop in this example
    sx: {
      fontWeight: 'bold',
      fontSize: _tableHeaderFontSize,
      color: "gray"
    },
  },

  muiTableBodyRowProps: ({ row }) => ({
    //conditionally style selected rows
    sx: {
      fontWeight: row.getIsSelected() ? 'bold' : 'normal',
      fontSize: _tableBodyFontSize,
      color: "gray"
    },
  }),
  muiTableBodyCellProps: ({ column }) => ({
    //conditionally style pinned columns
    sx: {
      fontWeight: column.getIsPinned() ? 'bold' : 'normal',
      fontSize: _tableBodyFontSize,
      FontFace: "sans-serif",
      border: 0,
      color: "gray"
    },
  }),
  displayColumnDefOptions: {
    'mrt-row-actions': {
      header: '', //change header text
      //size: 300, //make actions column wider
    },
  },
  muiTableBodyProps: {
    /*
    ADC to go live
    sx: {
      //stripe the rows, make odd rows a darker color
      '& tr:nth-of-type(odd) > td': {
        backgroundColor: '#f5f5f5',
      },
    },
    */
  },
  muiBottomToolbarProps: {

  },

  initialState: {
    pagination: {
      pageSize: 50,
      pageIndex: 0, // Optional: you can set the initial page index if needed
    },
  },


  muiPaginationProps: {
    // defaultPage:{

    // }


    rowsPerPageOptions: [10, 25, 50, 100],
    // showFirstButton: true,
    // showLastButton: true,
    // color: 'primary',
    // shape: 'rounded',
    showRowsPerPage: true,
    // count: {global_rowCount}
    // variant: 'outlined',

    sx: {
      fontSize: '15px',
      button: {
        fontWeight: 'bold',
        fontSize: "15px",
      },
      backgroundColor: '#f5f5f5',
    },

  },
};




  function utcDateToHHMMYYYYHHMM(utcDate) {
    // Ensure utcDate is a valid Date object
    utcDate = new Date(utcDate);
    
    if (!(utcDate instanceof Date) || isNaN(utcDate)) {
        console.log('Invalid Date object');
        return utcDate;
    }

    const options = {
      timeZone: "Europe/London",
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // Use 24-hour format
    };
    
    // Create a formatter with the UK timezone
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    
    // Format the UTC date to the UK timezone
    const ukDateStr = formatter.format(utcDate);
    return ukDateStr.replace(",", "");    
}

export function utcDateToYYYYMMDD(utcDate) {
  const date = new Date(utcDate);

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // 24-hour format
    timeZone: 'UTC', // Adjust if you want to convert to local time
  };

  const formattedDateParts = new Intl.DateTimeFormat('en-GB', options)
    .formatToParts(date)
    .reduce((acc, part) => {
      if (part.type !== 'literal') {
        acc[part.type] = part.value;
      }
      return acc;
    }, {});

  return`${formattedDateParts.year}-${formattedDateParts.month}-${formattedDateParts.day}`;

}

  export function utcDateToHHMMSS(utcDate) {
    // Ensure utcDate is a valid Date object
    utcDate = utcDate.toString();
    if (typeof utcDate === undefined) return '';
    if (typeof utcDate !== 'string') return '';

    let dattt = utcDate
    const options = {
      timeZone: "Europe/London",
/*      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
 */     hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // Use 24-hour format
    };
    // console.log(utcDate)
    var tmp = utcDate
    utcDate = new Date(utcDate);
    
    if (!isValidDate(utcDate)) {
        console.log("Invalid date:: ", utcDate)
        return '';
    }
      // console.log("disaodiou")
    if (!(utcDate instanceof Date) || isNaN(utcDate)) {
      // utcDate = "Tue Jul 23 2024 12:24:27 GMT+0100"
      
      console.log("InVLAID DATA", tmp)
      //throw new Error('Invalid Date object');
    }
    else{
      // console.log(tmp)
    }
     
    // Create a formatter with the UK timezone
    const formatter = new Intl.DateTimeFormat('en-GB', options);

    // Format the UTC date to the UK timezone
    const ukDateStr = formatter.format(utcDate);
    // console.log(ukDateStr)
    return ukDateStr;
  
}

function isValidDate(dt) {
  dt = new Date(dt);
  if (dt.toString() === "Invalid Date") return false;
  if (!(dt instanceof Date) || isNaN(dt)) {
    return false;
  }
  else {
    return true;
  }
}


export default AQDataTable;