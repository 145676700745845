import PageLoader from "../../../components/Loaders/PageLoader";
import { apiPost } from "../../../data/apidata";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";
import SiteSenseHistoryPage from "./SenseHistoryPage";

//component for SiteSenseHistory
const SiteSenseHistory = ({siteName}) => {
    
    const [siteSenseHistoryData, setSiteSenseHistoryData] = useState(null);
    const [startDate, setStartDate] = useState(new Date("2024-08-01"));
    const [endDate, setEndDate] = useState(new Date("2024-09-30"));
    
    const handleDateChange = () => {
      console.log("handleDateChange");
      console.log({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      });
      apiPost("/api/messages/sites/sense/history", {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      }, (data) => {
        
        console.log("Data fetched", data);
        setSiteSenseHistoryData(data);
      }, (error) => {
        console.log("Error fetching data", error);
      });
    };
  
  return (
      <div>
        <div className="date-picker">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
          />
          <button onClick={handleDateChange}>Fetch Data</button>
        </div>
        <div>
          {
            siteSenseHistoryData ? <SiteSenseHistoryPage data={siteSenseHistoryData} /> : <PageLoader />
          }
          
        </div>
      </div>
  );
}

export default SiteSenseHistory;